<script>
import Vue from "vue";
import store from "@/state/store";

import { required, email } from "vuelidate/lib/validators";

import { mapState } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/auth";
import {
  AccountStatus,
  notificationMethods, SessionLoginState
} from "@/state/helpers";



/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Account recovery",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      submitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  sockets: {

  },
  methods: {
    ...notificationMethods
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('recovery.title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
            >{{notification.message}}</div>
            <div class="p-2">
              <h4 class="text-center">
                {{$t('recovery.prompt')}}
              </h4>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="mt-3 text-center">
      <small>
        © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
        <small style="font-size: 10px;">
          <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
          /
          <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
        </small>
      </small>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>
